import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const HomeView = () => import(/* webpackChunkName: "home" */ '../views/HomeView.vue')
const AnalyticsView = () => import(/* webpackChunkName: "analytics" */ '../views/AnalyticsView.vue')
const AboutView = () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
const WebdevView = () => import(/* webpackChunkName: "webdev" */ '../views/WebdevView.vue')
const DatenschutzView = () => import(/* webpackChunkName: "datenschutz" */ '../views/DatenschutzView.vue')
const ImpressumView = () => import(/* webpackChunkName: "impressum" */ '../views/ImpressumView.vue')
const PlayView = () => import(/* webpackChunkName: "play" */ "../views/PlayView.vue")

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: "/about",
    name: "about",
    component: AboutView
  },
  {
    path: "/data-analytics",
    name: "analytics",
    component: AnalyticsView
  },
  {
    path: "/web-development",
    name: "webdev",
    component: WebdevView
  },
  {
    path: "/impressum",
    name: "impressum",
    component: ImpressumView
  },
  {
    path: "/datenschutz",
    name: "datenschutz",
    component: DatenschutzView
  },
  {
    path: "/t-rex",
    name: "t-rex",
    component: PlayView
  }
]

const router = new VueRouter({
  routes
})

export default router